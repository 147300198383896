a {
  color: #009dd9;
}

.container {
  background-color: white;
  border-radius: 10px;
  border: solid 2px #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
  width: 257px;
}

.container.last {
  margin-bottom: 10px;
}

.container.checked {
  background-color: #f1f1f1;
  border: solid 2px #dbdcdd;
}

.title {
  font-size: 22px;
  margin: 14px 10px;
  height: 100px;
  word-wrap: break-word;
}

.title.expanded {
  height: fit-content;
}

.completionLink {
  display: block;
  background-color: white;
  border: none;
  padding-bottom: 20px;
}

.boldHeading {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 10px;
}

.descriptionText {
  font-size: 16px;
  margin-top: 0;
  word-wrap: break-word;
}

.toggle {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5px;
  padding-top: 10px;
}

.expander {
  cursor: pointer;
  border-top: 1px solid #dbdcdd;
  height: 28px;
  margin: 0 -2px;
}

.subHeading {
  margin-bottom: 10px;
}

.actionLink {
  display: block;
  margin: 0 0 10px 12px;
  word-wrap: break-word;
}

.completionCheckDisable {
  float: right;
  pointer-events: none;
}

.completionCheck {
  float: right;
}

.descriptionContainer {
  line-height: 1.38;
  padding: 0 14px;
}

.completeDelete {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.ellipsis {
  cursor: pointer;
}

.frequencyText {
  color: #aaadb3;
  font-size: 14px;
}

.dueBy {
  font-size: 14px;
}

.dueByHeading {
  font-weight: 500;
}

.checkListItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.clickableLink {
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
  color: #009dd9;
  margin: 0 0 0 14px;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: inherit;
}

.clickableLink:focus {
  outline: none;
}

.unclickable {
  cursor: default;
}

.buttonsArea {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-left: 17px;
}

.saveBtn {
  background-color: var(--blue-royal);
  border: none;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  outline: none;
  padding: 8px 20px;
  margin-bottom: 20px;
}

.saveBtnFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1320px) {
  .container {
    width: 232px;
  }

  .title {
    font-size: 20px;
  }
}