.calendarIconWrapper {
    align-items: center;
    background: var(--grey-water);
    border-bottom: 1px solid var(--grey-metal);
    border-left: 1px solid var(--grey-metal);
    border-top: 1px solid var(--grey-metal);
    height: 40px;
    text-align: center;
    width: 40px;
    border: 1px solid var(--grey-metal);
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.calendarIconWrapper img {
    height: 24px;
    padding:6px;
    width: 24px;
}

.date {
    color: var(--grey-charcoal);
    font-size: 16px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.disabled {
    background-color: var(--grey-water);
    border: 1px solid var(--grey-clouds);
    color: var(--grey-soot);
}

.inputWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
}
