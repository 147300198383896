.contentWrapper {
    background-color: var(--grey-linen);
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

.overlay {
    background-color: rgba(0, 0, 0, 0) !important;
}
