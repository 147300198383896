.textArea {
    border: 1px solid var(--grey-metal);
    border-radius: 4px;
    color: var(--grey-charcoal);
    font-size: 16px;
    height: 150px;
    padding: 10px;
    resize: none;
    width: 100%;
}

.disabled {
    background-color: var(--grey-water);
    border: 1px solid var(--grey-clouds);
    color: var(--grey-soot);
}
