.filterHeader {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    padding-bottom: 15px;
    padding-top: 30px;
}

.buttonArea {
    display: flex;
    margin-top: 50px;
}

.applyBtn {
    align-self: center;
    background-color: var(--blue-royal);
    border: none;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
    padding: 8px;
    text-align: center;
    width: 85px;
}

.cancelBtn {
    background: none;
    border: none;
    color: var(--blue-royal);
    cursor: pointer;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
}

.lineRow {
    align-items: center;
    display: flex;
    margin-top: 20px;
}

.filterByText {
    color: var(--grey-soot);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-style: italic;
}

.line {
    border-bottom: 1px solid;
    border-color: var(--grey-soot);
    height: 1px;
    margin-left: 5px;
    width: 85%;
}

.showAll {
    width: 155px;
}

@media only screen and (max-width: 1600px) {

    .buttonArea {
        margin-top: 10px;
    }
}