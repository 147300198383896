.oneThirdBasis {
  margin-right: 14px;
}

.flexContainer {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.buttonsArea {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 50px;
}

.saveBtn {
  background-color: var(--blue-royal);
  border: none;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  outline: none;
  padding: 8px 20px;
}

.disabledSaveBtn {
  background-color: var(--blue-royal);
  border: none;
  color: var(--white);
  cursor: not-allowed;
  font-size: 16px;
  outline: none;
  padding: 8px 20px;
}

.cancelBtn {
  background-color: var(--white);
  border: none;
  color: var(--blue-royal);
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  outline: none;
  padding: 8px 20px;
}

.addBtn {
  margin-top: 45px;
  margin-right: 24px;
  align-items: center;
  cursor: pointer;
  display: flex;
}

.topDetails {
  display: flex;
  justify-content: space-between;
}

.topDetails ul {
  padding-left: 0px;
  margin-right: 34px;
}

.detailColumn {
  flex-direction: column;
}

.detailColumn li {
  list-style-type: none;
  padding-bottom: 10px;
}

.labels {
  font-weight: 500;
  padding-right: 10px;
}

.ulPosition {
  float: right;
}

.freqDropdown {
  margin-left: -17px;
}

.oneThird {
  flex-basis: 31%;
  margin-right: 14px;
}

.flexWidth {
  flex-basis: 100%;
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.buttonContainer {
  display: flex;
  align-items: center;
  position: relative;
  flex-basis: 100%;
}

.deleteBtn {
  padding: 0;
  height: 30px;
  float: right;
  position: absolute;
  right: 50px;
  background: transparent;
  border: 0 none;
  color: red;
  margin-top: 16px;
  cursor: pointer;
}

.maxheight {
  max-height: 700px;
  overflow-y: auto;
}
/* width */
.maxheight::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.maxheight::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.maxheight::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}

/* Handle on hover */
.maxheight::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}
