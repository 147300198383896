.container {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
}

.container span {
    font-weight: 500;
    padding-right: 10px;
}
.nobold{
    font-weight: normal !important;
}
.noboldli{
    overflow: visible !important;
    display: flex;
}
/* Tooltip container */
.nobold {
    position: relative;
    display: inline;
    overflow: visible;
     /* If you want dots under the hoverable text */
  }
 
 
 

.topDetails {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
}

.topDetails ul {
    padding-left: 0px;
}

.labels {
    font-weight: 500;
    padding-right: 10px;
}

.detailColumn {
    flex-direction: column;
    margin-top: 0px;
    text-overflow: ellipsis;
    width: 44%;
}

.detailColumn li {
    font-size: 12px;
    list-style-type: none;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.reason {
    margin: 0;
    font-size: 12px;
    padding-bottom: 10px;
}

.affectedTableTitle {
    margin: 0;
    padding-top: 30px;
    padding-bottom: 6px;
    font-size: 16px;
}

.affectedTable {
    border-collapse: collapse;
    border: 1px solid var(--grey-metal);
    font-size: 12px;
    text-align: center;
    width: 100%;
}

.affectedTable tbody tr:nth-child(odd) {
    background-color: var(--grey-water);
}

.affectedTable tbody td:nth-child(1) {
    font-weight: 500;
    padding-left: 20px;
    text-align: left;
}

.affectedTable th {
    padding-bottom: 12px;
    padding-top: 12px;
}

.affectedTable td {
    padding-bottom: 14px;
    padding-top: 14px;
    width: 33%;
}

.commentsTitle {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 30px;
}

.comments {
    font-size: 12px;
    margin: 0;
    padding: 10px;
    height: 100px;
    overflow: auto;
    border: 1px solid var(--grey-metal);
    line-height: 1.38;
}

.closeButton {
    background: none;
    border: none;
    color: var(--blue-royal);
    cursor: pointer;
    display: block;
    font-family: 'Gotham', sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 15px auto 0 auto;
}

.leftArrow {
    display: block;
    position: absolute;
    top: 50%;
    left: 96%;
}

.rightArrow {
    display: block;
    position: absolute;
    top: 50%;
    right: 96%;
}

.line {
    width: 92%;
 height: 1px;
 border-bottom: solid 1px var(--grey-clouds);
 position: absolute;
 top: 320px;
}

.spacing {
    height: 220px;
}

.staticImage {
    width: 92%;
    top: 147px;
    position: absolute;
}
