.topNav {
    display: flex;
    background-color: var(--white);
    flex-direction: row;
    height: 106px;
}

.hamburgerIcon {
    cursor: pointer;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto;
    padding-bottom: 39px;
    padding-left: 40px;
    padding-top: 39px;
    width: 35px;
}

.mainLogoSection {
    display: inline-block;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.mainLogoImage {
    height: 60px;
}

.mainLogoName {
    color: var(--blue-smurf);
    font-family: 'Gotham', sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: normal;
    padding-left: 7px;
    padding-top: 8px;
}

.mainLogoContainer {
    align-self: center;
    height: 60px;
    margin: auto;
    padding-bottom: 23px;
    padding-top: 23px;
}

.chevronAnchor {
    display: flex;
    text-decoration: none;
}
