.pageHeaderContainer {
  align-items: center;
  background-color: var(--blue-royal);
  display: flex;
  flex-direction: row;
  min-height: 80px;
}

.selectContainer {
  background-color: var(--white);
  border-radius: 4px;
  border: none;
  margin-right: 40px;
}

.selectContainer select {
  outline: none;
}

.selectContainer img {
  z-index: 2;
}

.selectContainerSmall {
  background-color: var(--white);
  border-radius: 4px;
  border: none;
  margin-right: 40px;
  width: 250px;
}

.selectContainerSmall select {
  width: 250px;
}

@media only screen and (max-width: 1050px) {
  .selectContainerSmall {
    width: 200px;
  }

  .selectContainerSmall select {
    width: 200px;
  }
}

@media only screen and (max-width: 925px) {
  .selectContainerSmall {
    width: 150px;
  }

  .selectContainerSmall select {
    width: 150px;
  }
}

.selectContainerSmall img {
  z-index: 2;
}

.pageHeader {
  align-items: center;
  color: var(--white);
  display: flex;
  flex-grow: 2;
  font-size: 42px;
  font-weight: 300;
  height: 100%;
  margin: 0;
  padding: 0 40px;
  white-space: nowrap;
}

@media only screen and (max-width: 1200px) {
  .pageHeader {
    font-size: 36px;
  }
}

@media only screen and (max-width: 1100px) {
  .pageHeader {
    font-size: 34px;
  }
}

@media only screen and (max-width: 1000px) {
  .pageHeader {
    font-size: 32px;
  }
}

.launchFilters {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  color: #fff;
  width: 170px;
  text-overflow: ellipsis;
  font-weight: 500;
}

.launchFiltersProd {
  align-items: center;
  cursor: pointer;
  text-align: right;
  justify-content: space-between;
  margin-right: 20px;
  color: #fff;
  width: 600px;
  text-overflow: ellipsis;
  font-weight: 500;
}

.launchFiltersProd>span {
  position: relative;
  top: -4px;
}

.launchIcon {
  background-color: #ffffff;
  height: 20px;
  width: 20px;
}

.launchIconProd {
  background-color: #ffffff;
  height: 20px;
  width: 20px;
  margin-left: 20px;
  margin-right: 30px;
}

.launchText {
  color: var(--blue-royal);
  font-family: 'Gotham', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}

.directReportCheckbox {
  color: white;
  margin-right: 75px;
  margin-top: 10px;
  font-size: larger;
}

@media only screen and (max-width: 1320px) {
  .selectInputZoomWidth {
    margin-right: 10px;
    width: 296px;
  }

  .selectInputZoomWidth select {
    width: 309px;
  }
}