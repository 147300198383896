.navBar {
  align-items: center;
  background: var(--white);
  box-shadow: 0 1px 3px 0 var(--grey-clouds);
  display: flex;
  min-height: 60px;
  padding-left: 40px;
  justify-content: space-between;
  width: 100%;
}

.scrollableContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
}

.addItemContainer {
  align-items: center;
  color: #0066b2;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  margin-right: 44px;
}

.userControlHeading {
  color: #0066b2;
  font-size: 20px;
  font-weight: 500;
}

.addSvg {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-right: 10px;
}

.link {
  color: var(--grey-soot);
  font-size: 20px;
  padding: 1em 2em;
  text-decoration: none;
}

.link:hover,
.active {
  border-bottom: solid 5px var(--blue-royal);
  color: var(--grey-charcoal);
  cursor: pointer;
  font-weight: 500;
  padding: 1em 2em calc(1em - 5px) 2em;
}

.content {
  display: flex;
  min-height: calc(100vh - 246px);
  padding: 25px;
}

.loading {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.controlFormText {
  color: #0066b2;
  font-size: 20px;
  font-weight: 500;
}
