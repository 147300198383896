.launchFilters {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    width: 118px;
}

.launchIcon {
    height: 20px;
    width: 20px;
}

.launchText {
    color: var(--blue-royal);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
}
