.reactTable {
  margin-top: 30px;
}

.loading {
  margin-top: 30px;
}

.noData {
  color: var(--grey-charcoal);
  font-family: 'Gotham', sans-serif;
  margin-top: 40px;
  text-align: center;
}

.customRouteButtonLink {
  align-items: normal;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgb(0, 0, 238);
  border-style: none;
  box-sizing: content-box;
  color: var(--blue-royal);
  cursor: pointer;
  display: inline;
  font: inherit;
  height: auto;
  padding: 0;
  perspective-origin: 0 0;
  text-align: start;
  transform-origin: 0 0;
  width: auto;
  -moz-appearance: none;
  -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
  -webkit-logical-width: auto;
}

@supports (-moz-appearance: none) {
  /* Mozilla-only */
  button::-moz-focus-inner {
    /* reset any predefined properties */
    border: none;
    padding: 0;
  }
  button:focus {
    /* add outline to focus pseudo-class */
    outline-style: dotted;
    outline-width: 1px;
  }
}
