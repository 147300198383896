.buttonArea {
    display: flex;
    margin-top: 50px;
}

.filterBtn {
    align-self: center;
    background-color: var(--blue-royal);
    border: none;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
    padding: 8px;
    text-align: center;
    width: 131px;
}

.cancelBtn {
    background: none;
    border: none;
    color: var(--blue-royal);
    cursor: pointer;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
}

@media only screen and (max-width: 1600px) {
    .buttonArea {
        margin-top: 10px;
    }
}