.targetToggleComponent {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    width: 210px;
    height: 28px;
}

.label {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.38;
}

@media only screen and (max-width: 1600px) {
    .targetToggleComponent {
        width: auto;
    }

}

@media only screen and (max-width: 1320px) {
    .targetToggleComponent select {
        width: 78px;
    }
}