.roundedSquare {
    background-color: var(--white);
    border-radius: 8px;
    border: solid 1px var(--grey-clouds);
    height: 261px;
    margin-left: 12px;
    overflow: hidden;
}

.chart {
    background-color: var(--white);
    margin-top: -10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: content-box;
}

.titleLegend {
    align-items: center;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
}

@media only screen and (max-width: 1200px) {
    .roundedSquare {
        margin-top: 30px;
    }
}

.noData {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    margin-top: 40px;
    text-align: center;
}
