.layout {
    display: flex;
    flex-direction: column;
    margin: 25px 40px 0px 40px;
}

.titleRow {
    border-bottom: solid 1px var(--grey-clouds);
    font-family: 'Gotham', sans-serif;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.titleAsset {
    align-content: center;
    align-items: flex-end;
    color: var(--grey-charcoal);
    display: flex;
    flex-direction: row;
    font-size: 30px;
    font-weight: bold;
    height: 47px;
    justify-content: space-between;
    padding-top: 27px;
}

.alignStart {
    justify-content: flex-start;
    white-space: nowrap;
}

.alignEnd {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
}

.titleRoute {
    align-items: flex-end;
    color: var(--grey-charcoal);
    display: flex;
    flex-direction: row;
    font-size: 30px;
    font-weight: bold;
    justify-content: space-between;
}

.name {
    align-self: center;
    justify-self: flex-start;
    white-space: nowrap;
}

.title {
    color: var(--grey-charcoal);
    font-size: 30px;
    font-weight: bold;
}

.titleRowLink {
    align-items: center;
    align-self: center;
    color: var(--blue-royal);
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    justify-content: flex-start;
    text-decoration: none;
    white-space: nowrap;
}

.taskButton {
    align-self: center;
    background-color: var(--blue-royal);
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    height: 24px;
    padding-top: 8px;
    text-align: center;
    text-decoration: none;
    width: 150px;
}

.detailsRow {
    display: flex;
    flex-direction: row;
    height: 261px;
}

.shortDetail {
    flex-basis: 25%;
}

.longDetail {
    flex-basis: 50%;
}

.smallBoxLoadingPage {
    text-align: center;
}

.totalAffected {
    font-weight: bold;
}

.infoIcon {
    margin-left: 18px;
    margin-bottom: 4px;
}

@media only screen and (max-width: 1200px) {
    .name {
        font-size: 22px;
    }

    .infoIcon {
        margin-left: 12px;
        margin-bottom: 2px;
    }
}
