.defaultStyles {
    display: inline-block;
}

.fullStyle {
    display: inline-block;
    width: 100%;
}

.selectContainer {
    align-items: center;
    display: flex;
    z-index: 1;
    width: 100%;
}

.selectBox {
    appearance: none;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid var(--grey-metal);
    color: var(--grey-charcoal);
    font-size: 16px;
    height: 40px;
    padding: 10px;
    padding-right: 30px;
    z-index: 3;
    width: 100%;
}

.selectArrow {
    margin-left: -36px;
    z-index: 2;
}

.disabled {
    background-color: var(--grey-water);
}

.disabled select {
    color: var(--grey-soot);
    border: 1px solid var(--grey-clouds);
}

.disabled img {
    filter: grayscale(1);
}

@media (max-width:1320px) {
    .selectBox {
        font-size: 14px;
        padding: 7px;
    }

    .selectArrow {
        width: 25px;
        margin-left: -25px;
    }

    .prodDayComponent.selectBox {
        font-size: 14px;
        padding: 10px;
        padding-left: 1px;
    }
}