.prev {
    left: 20%;
}

.next {
    right: 20%;
}

.next,
.prev {
    z-index: 1001;
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 30px;
    color: #0066b2;
    background-color: var(--white);
    text-align: center;
    cursor: pointer;
    margin: auto;
}

.next:hover,
.prev:hover {
    opacity: 1;
}
