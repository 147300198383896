.inputBox {
    background-color: var(--white);
    border-radius: 8px;
    border: solid 1px #979797;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    height: 50px;
    margin-bottom: -30px;
    margin-top: -15px;
    text-align: center;
    width: 90px;
}

.redBorder {
    border-color: var(--ruby-slippers);
    border-width: 3px;
}

.redBorder:focus {
    outline-width: 0;
}
