.layout {
    display: flex;
    flex-direction: column;
    margin: 25px 40px 0px 40px;
}

.titleRow {
    border-bottom: solid 1px var(--grey-clouds);
    font-family: 'Gotham', sans-serif;
    height: 65px;
    margin-bottom: 30px;
    padding-bottom: 10px;
}

.titleAsset {
    align-content: center;
    color: var(--grey-charcoal);
    display: flex;
    flex-direction: row;
    font-size: 30px;
    font-weight: bold;
    justify-content: space-between;
    padding-top: 22px;
}

.alignStart {
    align-items: center;
    align-self: center;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
}

.alignEnd {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
}

.alignEndRoute {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
}

.titleRoute {
    align-content: center;
    color: var(--grey-charcoal);
    display: flex;
    flex-direction: row;
    font-size: 30px;
    font-weight: bold;
    justify-content: space-between;
}

.name {
    align-items: center;
    align-self: center;
    justify-self: flex-start;
    white-space: nowrap;
}

.title {
    color: var(--grey-charcoal);
    font-size: 30px;
    font-weight: bold;
}

.titleRowLink {
    align-items: center;
    align-self: center;
    color: var(--blue-royal);
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    justify-content: flex-start;
    text-decoration: none;
    white-space: nowrap;
}

.iconContainer {
    align-items: center;
    align-self: center;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: normal;
    justify-content: flex-start;
    text-decoration: none;
    white-space: nowrap;
}

.img {
    height: 24px;
    padding-right: 10px;
    width: 26px;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .name {
        font-size: 22px;
    }
}