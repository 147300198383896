.roundedSquare {
    background-color: var(--grey-water);
    border-radius: 8px;
    border: solid 1px var(--grey-clouds);
    height: 261px;
    overflow: hidden;
}

.routeSection {
    -ms-overflow-style: none;
    height: calc(100% - 75px);
    overflow-y: scroll;
    padding-bottom: 5px;
    padding-top: 5px;
    scrollbar-width: none;
}

.routeSection::-webkit-scrollbar {
    display: none;
}
