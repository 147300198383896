.checkbox {
    display: none;
}

.checkboxImg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.checkboxOption {
    color: var(--grey-charcoal);
    display: flex;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
}

.textLeft {
    margin-right: 8px;
}
