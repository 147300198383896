.text {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    white-space: nowrap;
}

.title {
    background-color: var(--white);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 20px;
}

@media only screen and (max-width: 1000px) {
    .text {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1000px) {
    .text {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1200px) {
    .text {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1600px) {
    .text {
        font-size: 16px;
    }
}
