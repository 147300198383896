.headerRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.clearAll {
    color: var(--blue-royal);
    cursor: pointer;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    margin-top: 25px;
}

@media only screen and (max-width: 1600px) {

    .clearAll {
        margin-top: 10px;
    }
}