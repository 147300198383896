.title {
    color: var(--grey-charcoal);
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

.formBox {
    border: solid 1px var(--grey-metal);
    margin-top: 5px;
}

.formRow {
    align-items: center;
    color: var(--grey-charcoal);
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 60px;
    padding: 0 20px;
}

.formRow:nth-child(odd) {
    background-color: white;
}

.formRow:nth-child(even) {
    background-color: var(--grey-water);
}

.label {
    flex: 2;
}

.labelHours {
    font-size: 14px;
    font-weight: 400;
    padding-left: 14px;
}

.modalLabel {
    display: inline-block;
    color: var(--grey-charcoal);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 40px;
    padding-right: 10px;
}

.value {
    text-align: center;
}

.value input {
    border: 1px solid var(--grey-metal);
    border-radius: 4px;
    color: var(--grey-charcoal);
    font-size: 16px;
    height: 34px;
    text-align: center;
    width: 52px;
}

.required {
    border: solid 3px var(--ruby-slippers) !important;
}

.required:focus {
    outline-width: 0;
}

.buttonRow {
    display: flex;
    margin-top: 30px;
    width: 100%;
}

.left {
    align-items: center;
    display: flex;
    flex-grow: 1;
}

.right {
    align-items: center;
    display: flex;
}

.errorMessage {
    margin-top: 10px;
    margin-left: -12px;
}

.popOver {
    padding-right: 8px;
}

.popOverIndented {
    margin-left: 32px;
    padding-right: 8px;
}

.disabled {
    background-color: var(--grey-water);
    border: 1px solid var(--grey-clouds);
    color: var(--grey-soot);
    cursor: not-allowed;
}

.dayTimeFieldBox{
    position: fixed;
    left: 400px;
    top: 219px;
}

