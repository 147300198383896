.wrapper {
    align-items: center;
    display: flex;
    font-family: 'Gotham', sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 40px;
    letter-spacing: normal;
    line-height: normal;
    margin-right: 20px;
}

.dot {
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin-left: 10px;
    margin-right: 5px;
    width: 8px;
}

.target {
    background-color: var(--green);
}

.production {
    background-color: var(--grey-metal);
}

.downtime {
    background-color: var(--blue-royal);
}

.constrained {
    background-color: var(--ruby-slippers);
}

.shutin {
    background-color: var(--yellow);
}
