.inactiveRoute {
    font-weight: normal;
}

.inactiveRoute:hover {
    font-weight: bold;
}

.activeRoute {
    background-color: var(--blue-deepSea);
    font-weight: bold;
}

.inactiveRoute, .activeRoute {
    align-items: center;
    color: var(--white);
    display: flex;
    flex-direction: row;
    font-family: 'Gotham', sans-serif;
    font-size: 22px;
    height: 62px;
    line-height: 62px;
    padding-left: 37px;
    text-align: left;
    vertical-align: middle;
    width: calc(100% - 37px);
}

.img {
    height: 24px;
    padding-right: 10px;
    width: 26px;
}

.center {
    justify-content: center;
    padding-left: 0;
    width: 100%;
}

.link {
    text-decoration: none;
}
