.chart {
    background-color: var(--white);
    margin-top: -10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: content-box;
}

.noData {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    margin-top: 40px;
    text-align: center;
}
