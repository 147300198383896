.cardContainer {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.cardContainerCenter {
    justify-content: center;
}

.card {
    background: var(--white);
    border-radius: 8px;
    box-shadow: 0 0 0 1px var(--grey-clouds);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 56px;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: 350px;
}

.longerCard {
    width: 445px;
}

.card:hover {
    box-shadow: 0 0 0 2px var(--blue-baby);
}

.disabled {
    cursor: not-allowed;
    box-shadow: 0 0 0 1px var(--grey-clouds);
    background: var(--grey-water);
}

.disabled:hover {
    box-shadow: 0 0 0 1px var(--grey-clouds);
}

.centered {
    text-align: center;
    justify-content: center;
}

.selectedCard {
    box-shadow: 0 0 0 2px var(--blue-royal);
    cursor: auto;
}

.link {
    color: var(--blue-royal);
    cursor: pointer;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: right;
    margin-left: 16px;
    margin-right: auto;
    user-select: none;
    white-space: nowrap;
}

.separator {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    margin-left: 5px;
    margin-right: 5px;
    letter-spacing: normal;
    line-height: normal;
    user-select: none;
}

.refresh {
    color: var(--blue-royal);
    cursor: pointer;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: right;
    margin-left: auto;
    margin-right: 10px;
    user-select: none;
    white-space: nowrap;
}

@media only screen and (max-width: 1500px) {
    .link {
        font-size: 12px;
    }

    .refresh {
        font-size: 12px;
    }
}

.title {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    user-select: none;
}

.titlePrimary {
    padding-bottom: 7px;
    padding-top: 16px;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
}

.titleSecondary {
    text-overflow: ellipsis;
    white-space: nowrap;
}

.formattedNumber {
    color: var(--grey-charcoal);
    font-size: 22px;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
    user-select: none;
    vertical-align: middle;
}

.formattedNumberPrimary {
    line-height: normal;
    padding-bottom: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.formattedNumberSecondary {
    line-height: normal;
    margin-left: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.green {
    color: var(--green-icing);
}

.red {
    color: var(--ruby-slippers);
}

.childCard {
    align-self: center;
    margin-left: auto;
}

.primary {
    align-items: center;
    flex-direction: column;
    height: 90px;
    position: relative;
    width: 280px;
}

.secondary {
    align-content: center;
    height: 56px;
    position: relative;
    width: 445px;
}

.newBubble {
    -moz-border-radius: 24px;
    -webkit-border-radius: 24px;
    background-color: var(--red-apple);
    border-radius: 24px;
    height: 24px;
    position: absolute;
    right: -12px;
    text-align: center;
    top: -12px;
    width: 24px;
}

.bubbleNumber {
    user-select: none;
    -ms-user-select: none;
    color: var(--white);
    color: white;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    position: relative;
    text-align: center;
    top: -15px;
}

.bubbleNumberSmall {
    user-select: none;
    -ms-user-select: none;
    color: var(--white);
    color: white;
    font-family: 'Gotham', sans-serif;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    position: relative;
    text-align: center;
    top: -17px;
}
