.hoverContent {
    align-items: center;
    background-color: var(--grey-charcoal);
    border-radius: 5px;
    color: var(--white);
    display: flex;
    position: relative;
    font-family: 'Gotham', sans-serif;
    font-size: 13px;
    opacity: 1;
    padding: 8px;
    z-index: 10000;
}

.hoverImage {
    height: 29px;
    padding-right: 2px;
    width: 29px;
    
}

.hoverText {
    color: var(--white);
    margin-top: 4px;
    padding-left: 2px;
    white-space: pre-line;
}

.childComponent {
    display: inline-block;
}

.overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}
