.roundedSquare {
    background-color: var(--white);
    border-radius: 8px;
    border: solid 1px var(--grey-clouds);
    height: 261px;
    margin-left: 12px;
    overflow: hidden;
}

.detailsTable {
    border-spacing: 0px;
    font-family: 'Gotham', sans-serif;
    height: calc(100% - 65px);
    width: 100%;
}

.detailsTable td {
    border: 0;
}

.detailsTable tr:nth-child(odd) {
    background-color: var(--grey-water);
}

.detailsTableValue {
    color: var(--grey-charcoal);
    font-size: 18px;
    font-weight: bold;
    padding-right: 20px;
    text-align: right;
    white-space: pre-wrap;
}

.detailsTableKey {
    color: var(--grey-charcoal);
    font-size: 16px;
    margin-left: 30px;
    padding: 0 0 0 20px;
    width: 60%;
    white-space: pre-wrap;
}

@media only screen and (max-width: 1600px) {
    .detailsTableValue {
        font-size: 12px;
    }

    .detailsTableKey {
        font-size: 14px;
    }
}
