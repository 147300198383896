.container {
    margin-right: 20px;
    min-width: 254px;
}

.title {
    color: #333333;
    font-size: 27px;
    line-height: 1.29;
    width: 281px;
    height: 71px;
}

.columnWrapper {
    background-color: #F1F1F1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    width: 100%;
}

@media only screen and (max-width: 1320px) {
    .title {
        font-size: 25px;
        width: 264px;
    }
}