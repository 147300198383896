

.flexContainer {
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.text {
    border: 1px solid var(--grey-metal);
    border-radius: 4px;
    color: var(--grey-charcoal);
    font-size: 16px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
}
.halfbasis{
    flex-basis: 48%;
    margin-right: 20px;
}
.oneTenthBasis{
    flex-basis: 5%;
    margin-right: 18px;
    margin-top: 45px;
}
.closeblue{
    width: 117%;
}
.error {
    color: var(--ruby-slippers);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: italic;
    font-weight: normal;
    letter-spacing: -0.1px;
    line-height: normal;
    margin-left: 10px;
}
.label {
    color: var(--grey-charcoal);
    display: block;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    margin-top: 25px;
    margin-bottom: 5px;
}


.text::placeholder {
    color: var(--grey-soot);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: italic;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.38;

}
.radiolabel{
    align-items: center;
    flex-basis: 20%;
}
.radiodiv{
    display: flex;
    flex-basis: 75%;
}
.firstPart{
    display: flex;
    flex-basis: 91%;
    align-items: center;
}
.addLinkRow{
    margin-left: 0px;
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.typebasis{
    display: flex;
    align-items: center;
}