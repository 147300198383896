.navBar {
    background: var(--white);
    box-shadow: 0 1px 3px 0 var(--grey-clouds);
    display: flex;
    width: 100%;
}

.scrollableContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.link {
    color: var(--grey-soot);
    font-size: 20px;
    padding: 1em 2em;
    text-decoration: none;
}

.link:hover,
.active {
    border-bottom: solid 5px var(--blue-royal);
    color: var(--grey-charcoal);
    cursor: pointer;
    font-weight: 500;
    padding: 1em 2em calc(1em - 5px) 2em;
}
