.userInfo {
    padding-top: 40px;
    text-align: center;
    vertical-align: center;
}

.userArea {
    display: block;
}

.firstLastName {
    color: white;
    font-family: 'Gotham', sans-serif;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 5px;
    text-align: center;
}

.secondaryUserInfo {
    color: white;
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.38;
    margin-top: 5px;
    text-align: center;
}

@media (max-width:1320px) {
    .userInfo {
        padding-top: 20px;
    }
}