.container {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
}

.container span {
    font-weight: 500;
    padding-right: 10px;
}



.topDetails {
    display: flex;
    justify-content: space-between;
}

.topDetails ul {
    padding-left: 0px;
}

.labels {
    font-weight: 500;
    padding-right: 10px;
}

.detailColumn {
    flex-direction: column;
}
.detailColumnTwo{
    flex-direction: column;
   margin-right: 85px;
}
.detailColumn li,.detailColumnTwo li {
    font-size: 12px;
    list-style-type: none;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.reason {
    margin: 0;
}

.commentsTitle {
    font-weight: 500;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 30px;
}

.comments {
    border: 1px solid var(--grey-metal);
    height: 100px;
    margin: 0;
    overflow: auto;
    padding: 10px;
}

.closeButton {
    background: none;
    border: none;
    color: var(--blue-royal);
    cursor: pointer;
    display: block;
    font-family: 'Gotham', sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 30px auto 0 auto;
}
