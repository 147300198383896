.container {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
}

.container span {
    font-weight: 500;
    padding-right: 10px;
}

.topDetails {
    display: flex;
    justify-content: space-between;
}

.topDetails ul {
    padding-left: 0px;
}

.labels {
    font-weight: 500;
    padding-right: 10px;
}

.detailColumn {
    flex-direction: column;
}
.detailColumn li {
    font-size: 12px;
    list-style-type: none;
    padding-bottom: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.reason {
    margin: 0;
}

.affectedTableTitle {
    margin: 0;
    padding-top: 30px;
    padding-bottom: 6px;
}

.affectedTable {
    border-collapse: collapse;
    border: 1px solid var(--grey-metal);
    text-align: center;
    width: 100%;
}

.affectedTable tbody tr:nth-child(odd) {
    background-color: var(--grey-water);
}

.affectedTable tbody td:nth-child(1) {
    font-weight: 500;
    padding-left: 20px;
    text-align: left;
}

.affectedTable th {
    padding-bottom: 12px;
    padding-top: 12px;
}

.affectedTable td {
    padding-bottom: 14px;
    padding-top: 14px;
    width: 33%;
}

.commentsTitle {
    font-weight: 500;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 30px;
}

.comments {
    margin: 0;
    padding: 10px;
    height: 100px;
    overflow: auto;
    border: 1px solid var(--grey-metal);
    line-height: 1.38;
}

.closeButton {
    background: none;
    border: none;
    color: var(--blue-royal);
    cursor: pointer;
    display: block;
    font-family: 'Gotham', sans-serif;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: 30px auto 0 auto;
}
