.text {
    border: 1px solid var(--grey-metal);
    border-radius: 4px;
    color: var(--grey-charcoal);
    font-size: 16px;
    line-height: 40px;
    padding: 0 10px;
    width: 100%;
}

.disabled {
    background-color: var(--grey-water);
    border: 1px solid var(--grey-clouds);
    color: var(--grey-soot);
}

.text::placeholder {
    color: var(--grey-soot);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: italic;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.38;
}
