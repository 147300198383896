.container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 304px;
}

.chevronLeft {
    cursor: pointer;
    margin-right: 14px;
}

.iconAndText {
    align-items: center;
    display: flex;
}

.calendarIcon {
    margin-right: 12px;
}

.monthYearText {
    color: #0066b2;
    font-size: 20px;
    font-weight: 500;
}

.chevronRight {
    margin-left: 14px;
    cursor: pointer;

}

.disabled {
    cursor: not-allowed;
}
