.exportButton {
    align-self: center;
    background-color: var(--blue-royal);
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    text-align: center;
    width: 150px;
}

.csvLink {
    text-decoration: none;
}
