.adminWrapper {
  height: 100%;
}

.adminNavBar {
  background: var(--white);
  box-shadow: 0 1px 3px 0 var(--grey-clouds);
  display: flex;
  width: 100%;
}

.link {
  color: var(--grey-soot);
  font-size: 20px;
  padding: 1em 2em;
}

.link:hover,
.active {
  border-bottom: solid 5px var(--blue-royal);
  color: var(--grey-charcoal);
  cursor: pointer;
  font-weight: 500;
  padding: 1em 2em calc(1em - 5px) 2em;
}

.csvLink {
  display: none;
}

.csvWrapper {
  position: relative;
}

.exportButtonControls {
  border: 0 none;
  align-self: center;
  background-color: var(--blue-royal);
  color: white;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  right: 264px;
  top: -93px;
  width: 230px;
  padding: 8px;
}

.exportButtonControls:hover,
.exportButtonControls:focus,
.exportButtonControls:visited {
  border: none;
  outline: none;
}

.cardContainerActive {
  position: relative;
  top: 10px;
}

.content {
  margin: 3em 40px;
}

.comingSoon {
  margin-top: 7em;
  text-align: center;
}

.loading {
  padding-top: 40px;
}

.actionItem {
  color: var(--blue-royal);
  cursor: pointer;
  float: right;
  font-family: 'Gotham', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: right;
}

.flexContainer {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.label {
  color: var(--grey-charcoal);
  display: block;
  font-family: 'Gotham', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 25px;
  margin-bottom: 5px;
}

.radiolabel {
  display: block;
  font-family: 'Gotham', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;

}

.radioContainerTaskModal {
  margin-top: 35px;
  padding-bottom: 10px;
}

.disabledLabel {
  color: var(--grey-soot);
  display: block;
  font-family: 'Gotham', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
  margin-top: 25px;
  margin-bottom: 5px;
}

.error {
  color: var(--ruby-slippers);
  font-family: 'Gotham', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: italic;
  font-weight: normal;
  letter-spacing: -0.1px;
  line-height: normal;
}

.checkBoxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 29px;
  width: 50%;
}

.checkBoxesFull {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 29px;
}

.checkboxesLabel {
  display: block;
  font-weight: 500;
  margin-top: 30px;
}

.checkBoxesFullBaselineRole {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.buttonsArea {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 50px;
}

.saveBtn {
  background-color: var(--blue-royal);
  border: none;
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  outline: none;
  padding: 8px 20px;
}

.disabledSaveBtn {
  background-color: var(--blue-royal);
  border: none;
  color: var(--white);
  cursor: not-allowed;
  font-size: 16px;
  outline: none;
  padding: 8px 20px;
}

.cancelBtn {
  background-color: var(--white);
  border: none;
  color: var(--blue-royal);
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  outline: none;
  padding: 8px 20px;
}

.formErrorSection {
  margin-bottom: -20px;
  margin-left: -5px;
  width: 55%;
  margin-top: -5px;
}

.basis {
  flex-basis: 45%;
  margin-right: 25px;
}

.halfBasis {
  flex-basis: 48%;
}

.basisZindex {
  flex-basis: 45%;
  margin-right: 25px;
  z-index: 6;
}

.fullBasis {
  flex-basis: 100%;
  margin-right: 25px;
}

.fullBasisRadio {
  flex-basis: 100%;
  margin-right: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fullBasisRadioNoSpace {
  flex-basis: 100%;
  margin-right: 25px;
  display: flex;
  align-items: center;
}

.maxheight {
  height: 745px;
  max-height: 700px;
  overflow-y: auto;
  min-height: 775px;
}

.radiodiv {
  display: flex;
}

.oneThirdBasis {
  flex-basis: 31%;
  margin-right: 13px;
}

.successModal {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.successIcon {
  flex-direction: row;
  height: 60px;
  width: 60px;
}

.successMessage {
  color: var(--grey-charcoal);
  flex-direction: row;
  font-family: 'Gotham', sans-serif;
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
}

.select select {
  color: var(--grey-soot);
  font-family: 'Gotham', sans-serif;
  font-size: 16px;
  font-stretch: normal;
  font-style: italic;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.38;
}

.disableBtn {
  background-color: var(--white);
  border: none;
  color: var(--grey-soot);
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
  outline: none;
  padding: 8px 20px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  position: relative;
  flex-basis: 100%;
}

/* width */
.maxheight::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.maxheight::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.maxheight::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}

/* Handle on hover */
.maxheight::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}

.deleteBtn {
  padding: 0;
  height: 30px;
  float: right;
  position: absolute;
  right: 10px;
  background: transparent;
  border: 0 none;
  color: red;
  margin-top: 16px;
  cursor: pointer;
}

.deleteBtnRole {
  padding: 0;
  height: 30px;
  float: right;
  position: absolute;
  right: 58px;
  bottom: 50px;
  background: transparent;
  border: 0 none;
  color: red;
  margin-top: 16px;
  cursor: pointer;
}

.fullWidthSelectInput {
  flex-basis: 100%;
  margin-right: 0px;
}

.multiselect {
  width: 250px;
  position: fixed;
  background: #fff;
}

.multiselectRole {
  width: 350px;
  position: fixed;
  background: #fff;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  font-weight: bold;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.checkBoxLabel {
  display: none;
  width: 250px;
  height: 300px;
  overflow-y: scroll;
  border: 1px #dadada solid;
  background: #fff;
}

/* width */
.checkBoxLabel::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.checkBoxLabel::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.checkBoxLabel::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}

/* Handle on hover */
.checkBoxLabel::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}

.checkBoxLabelRole {
  display: none;
  width: 350px;
  height: 300px;
  overflow-y: scroll;
  border: 1px #dadada solid;
  background: #fff;
}

/* width */
.checkBoxLabelRole::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.checkBoxLabelRole::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.checkBoxLabelRole::-webkit-scrollbar-thumb {
  background: #c0c0c0;
}

/* Handle on hover */
.checkBoxLabelRole::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}

#checkboxes label {
  display: block;
}

#checkboxes label:hover {
  background-color: #1e90ff;
}

.listMargin {
  margin: 12px;
}

.fix {
  position: absolute;
  top: 0;
}

.checkedSpace {
  margin-right: 6px;
}

.stopClick {
  pointer-events: none;
  cursor: default;
}

.topDetails {
  display: flex;
  justify-content: space-between;
}

.topDetails ul {
  padding-left: 0px;
  margin-right: 34px;
}

.detailColumn {
  flex-direction: column;
}

.detailColumn li {
  list-style-type: none;
  padding-bottom: 10px;
}

.labels {
  font-weight: 500;
  padding-right: 10px;
}

.ulPosition {
  float: right;
}

@media only screen and (max-width: 1600px) {
  .fullBasisRadioNoSpace {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1600px) {

  .label {
    margin-top: 10px;
  }
}