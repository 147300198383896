.leftArrow {
    display: block;
    position: absolute;
    top: 42%;
    right: 5%;
}

.rightArrow {
    display: block;
    position: absolute;
    top: 42%;
    left: 5%;
}
