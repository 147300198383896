.radio {
    display: none;
}

.radioImg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.radioOption {
    color: var(--grey-charcoal);
    display: flex;
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
    margin-left: 40px;
}


@media only screen and (max-width: 1600px) {
    .radioOption {
        margin-left: 10px;
    }

}