.title {
  color: var(--grey-charcoal);
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  position: relative;
  display: flex;
  margin-bottom: 10px;
}

.selectAssetFeild {
  position: absolute;
  right: 287px;
  top: -15px;
}

.selectAssetRouteFeild {
  position: absolute;
  right: 379px;
  top: -15px;
}

.selectRouteField {
  position: absolute;
  right: 0;
  top: -15px;
}

.selectRouteYear {
  position: absolute;
  right: 684px;
  top: -15px;
}

.selectYearFeild {
  position: absolute;
  right: 500px;
  top: -15px;
}

.inputtype {
  width: 100px;
  margin: 0px 10px;
}

.selectAssetDropdown {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--grey-metal);
  color: var(--grey-charcoal);
  font-size: 16px;
  height: 34px;
  padding: 7px;
  padding-right: 30px;
  z-index: 3;
  width: 100%;
}

.selectAssetRouteDropdown {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--grey-metal);
  color: var(--grey-charcoal);
  font-size: 16px;
  height: 34px;
  padding: 7px;
  padding-right: 30px;
  z-index: 3;
  width: 300px;
}

.selectRouteDropdown {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--grey-metal);
  color: var(--grey-charcoal);
  font-size: 16px;
  height: 34px;
  padding: 7px;
  padding-right: 30px;
  z-index: 3;
  width: 375px;
}

.formBox {
  border: solid 1px var(--grey-metal);
  margin-top: 5px;
}

.formRow {
  align-items: center;
  color: var(--grey-charcoal);
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 60px;
  padding: 0 20px;
}

.formRow:nth-child(odd) {
  background-color: white;
}

.formRow:nth-child(even) {
  background-color: var(--grey-water);
}

.label {
  flex: 2;
}

.value {
  flex: 1;
  text-align: center;
}

.valueRoute {
  flex: 0.57;
  text-align: center;
}

.value input {
  border: 1px solid var(--grey-metal);
  border-radius: 4px;
  color: var(--grey-charcoal);
  font-size: 16px;
  height: 34px;
  margin: 10px 0;
  text-align: center;
  width: 122px;
}

.required {
  border: solid 3px var(--ruby-slippers) !important;
}

.required:focus {
  outline-width: 0;
}

.buttonRow {
  display: flex;
  margin-top: 30px;
  width: 100%;
}

.left {
  align-items: center;
  display: flex;
  flex-grow: 1;
  color: var(--blue-royal);
}

.right {
  align-items: center;
  display: flex;
}

.errorMessage {
  margin-top: 10px;
  margin-left: -12px;
}

@media only screen and (max-width: 1600px) {
  .inputtype {
    width: 57px;
    margin: 0px 4px;
  }

  .valueRoute {
    flex: 1.3;
  }

  .selectRouteYear {
    right: 564px;
  }

  .selectAssetRouteFeild {
    right: 313px;
  }

  .selectAssetRouteDropdown {
    width: 240px;
  }

  .selectRouteDropdown {
    width: 305px;
  }
}

@media only screen and (max-width: 1320px) {
  .selectRouteDropdown {
    width: 320px;
    padding-right: 10px;
    font-size: 14px;
  }

  .selectAssetRouteDropdown,
  .selectAssetDropdown {
    font-size: 14px;
  }

  .selectAssetRouteFeild {
    right: 330px;
  }

  .selectRouteYear {
    right: 580px;
  }
}