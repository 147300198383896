.targetHistorySection {
    background-color: var(--grey-water);
    padding-top: 21px;
    width: 100%;
}

.targetHistoryHeader {
    align-content: center;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    height: 53px;
    justify-content: space-between;
}

.title {
    color: var(--grey-charcoal);
    font-family: Gotham, sans-serif;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    margin: 0;
}

.icon {
    cursor: pointer;
    padding-right: 20px;
}

.disabled {
    cursor: not-allowed;
}

.alignLeft {
    align-content: center;
    height: 53px;
    padding-left: 36px;
}

.alignRight {
    align-content: center;
    display: flex;
    justify-content: flex-end;
    height: 53px;
    padding-right: 36px;
}

.select {
    width: 230px;
    position: relative;
    top: 7px;
}

.table {
    background-color: var(--white);
    margin-top: 10px;
}

.chartContainer {
    background-color: var(--white);
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    height: 300px;
}
