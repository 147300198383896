.modalOverlay {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
}

.modalContainer {
    background-color: var(--white);
    border-radius: 7px;
    color: var(--grey-charcoal);
    display: flex;
    flex-direction: column;
    left: 20rem;
    max-width: 80%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 29px;
    position: absolute;
    width: 700px;
}

.modalContainerSmall {
    left: 50%;
    max-height: 40%;
    top: 25%;
    transform: translate(-50%, -50%);
    width: 25%;
    overflow-y: hidden;
}

.modalContainerLpoFilter {
    left: 50%;
    height: 780px;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 800px;
    overflow-y: hidden;
}

.modalContainerAssetFilter {
    left: 50%;
    height: 400px;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 800px;
    overflow-y: hidden;
}

.modalContainerOpenEventDetails {
    left: 50%;
    height: 676px;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 700px;
    overflow-y: hidden;
}

.modalContainerOpenPullRequestEventDetails {
    left: 50%;
    height: 810px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 850px;
    overflow-y: hidden;
}

.modalContainerShutInEventDetails {
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 700px;
    overflow-y: hidden;
}

.modalContainerWitDetailModalDetails {
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 860px;
    overflow-y: hidden;
}

.modalContainerOpenWorkOrderEventDetails {
    left: 50%;
    height: 870px;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 850px;
    overflow-y: hidden;
}

.modalContainerCustomAsset {
    left: 50%;
    height: 560px;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 2000px;
    overflow-y: hidden;
}

.modalContainerThresholdAdjust {
    left: 50%;
    max-height: 65%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 525px;
}

.pendingMessage {
    align-items: center;
    background-color: var(--white);
    border-radius: 8px;
    color: var(--grey-charcoal);
    display: flex;
    flex-direction: column;
    height: auto;
    left: 20rem;
    left: 38%;
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 29px;
    position: absolute;
    top: 25%;
    width: 380px;
}

.modalContainerMedium {
    left: 50%;
    max-height: 85%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modalContainerLarge {
    left: 7%;
    max-height: 80%;
    top: 15%;
    width: 85%;
}

.modalHeader {
    border-bottom: solid 1px var(--grey-clouds);
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    justify-content: space-between;
    padding-bottom: 10px;
}

.modalTitle {
    color: var(--grey-charcoal);
    display: inline-block;
    font-family: 'Gotham', sans-serif;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
}

.modalCloseIcon {
    cursor: pointer;
    float: right;
    height: 30px;
    margin-left: auto;
    width: 30px;
}

.modalBody {
    margin-top: 20px;
}

.modalContainerActionItemEventDetails {
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    width: 850px;
    overflow-y: hidden;
}

.modalContainerSmallSuccessMeassage {
    left: 50%;
    max-height: 40%;
    top: 25%;
    transform: translate(-50%, -50%);
    width: 44%;
    overflow-y: hidden;
    padding: 0px;
}

@media only screen and (max-width: 1600px) {
    .modalContainerCustomAsset {
        width: 1000px;
        height: 515px;
    }
}

@media only screen and (max-width: 1600px) {
    .modalContainerLpoFilter {
        top: 52%;
        width: 1300px;
        padding: 9px;
        height: 570px;
        max-width: 90%;

    }

    .label,
    .clearAll {
        margin-top: 10px;
    }

}