.noData {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    margin-top: 40px;
    text-align: center;
}

.loading {
    margin-top: 30px;
}

.reactTable {
    margin-top: 30px;
}
