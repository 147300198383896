.wrapper {
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 75px;
}

.title {
    font-size: 28px;
    line-height: 1.29;
    color: var(--grey-charcoal);
    flex-grow: 1;
}

.roleBtn{
    margin-right: 30px;
}