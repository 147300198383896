.routeCard {
    align-items: center;
    background-color: var(--white);
    border-color: transparent;
    border-radius: 4px;
    box-shadow: 0 0 0 1px var(--grey-clouds);
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: space-between;
    margin-bottom: 6px;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
}

.routeCard:hover {
    box-shadow: 0 0 0 2px var(--blue-baby);
}

.routeSelected {
    box-shadow: 0 0 0 2px var(--blue-royal);
}

.name {
    color: var(--grey-charcoal);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-left: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 225px;
}

@media only screen and (max-width: 1600px) {
    .name {
        font-size: 10px;
        width: 185px;
    }

    .difference {
        font-size: 12px !important;
    }

    .roundedSquare {
        margin-top: 30px;
    }
}

.difference {
    font-family: 'Gotham', sans-serif;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: normal;
    line-height: normal;
    margin-left: 20px;
    margin-right: 20px;
}

.differenceGreen {
    color: var(--green-icing);
}

.differenceRed {
    color: var(--ruby-slippers);
}
