.title {
    color: var(--grey-charcoal);
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    position: relative;
    display: flex;
    margin-bottom: 10px;
}
.selectAssetFeild{
    position: absolute;
    right: 0;
    top:-15px;
}
.selectYearFeild{
    position: absolute;
    right: 282px;
    top: -15px;
}
.inputtype{
    width: 120px;
   
}
.selectAssetYearDropdown{
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid var(--grey-metal);
    color: var(--grey-charcoal);
    font-size: 16px;
    height: 34px;
    padding: 7px;
    padding-right: 30px;
    z-index: 3;
    width: 100%
}

.selectAssetDropdown{
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid var(--grey-metal);
    color: var(--grey-charcoal);
    font-size: 16px;
    height: 34px;
    padding: 7px;
    padding-right: 30px;
    z-index: 3;
    width: 275px;
}
.formBox {
    border: solid 1px var(--grey-metal);
    margin-top: 5px;
}

.formRow {
    align-items: center;
    color: var(--grey-charcoal);
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 60px;
    padding: 0 20px;
}

.formRow:nth-child(odd) {
    background-color: white;
}

.formRow:nth-child(even) {
    background-color: var(--grey-water);
}

.label {
    flex: 2;
}

.value {
    flex: 1;
    text-align: center;
    margin: 0px 3px;
}

.value input {
    border: 1px solid var(--grey-metal);
    border-radius: 4px;
    color: var(--grey-charcoal);
    font-size: 16px;
    height: 34px;
    margin: 10px 0;
    text-align: center;
    
}

.required {
    border: solid 3px var(--ruby-slippers) !important;
}

.required:focus {
    outline-width: 0;
}

.buttonRow {
    display: flex;
    margin-top: 30px;
    width: 100%;
}

.left {
    align-items: center;
    display: flex;
    flex-grow: 1;
    color: var(--blue-royal);
}

.right {
    align-items: center;
    display: flex;
}

.errorMessage {
    margin-top: 10px;
    margin-left: -12px;
}
@media only screen and (max-width: 1600px) {
.customWidth{
    width: 60px;
}
}