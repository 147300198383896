.button,
.taskListLoadMore,
.taskComplete,
.taskSave,
.cancelButton,
.commentSave,
.taskListLoadMore {
    width: 20%;
    margin-bottom: 1.5rem;
}

.taskListLoadMore,
.taskComplete,
.commentSave,
.controlPrimary {
    background-color: var(--md-blue);
    font-size: var(--smallSize);
    color: var(--white);
}

.taskListLoadMore:hover,
.taskComplete:hover {
    background-color: var(--white);
    border-color: var(--cerulean);
    color: var(--cerulean);
}

.newRecord ,.newRecordRole{
    background-color: var(--blue-royal);;
    font-size: var(--smallSize);
    color: var(--white);
    height: 32px;
    width: 230px;
    text-align: center;
    padding: 7px;
    font-weight: 500;
    cursor: pointer;
}
.newRecordRole{
    margin-right: 30px;
}
.thresholdSave {
    background-color: var(--blue-royal);;
    font-size: var(--smallSize);
    color: var(--white);
    height: 32px;
    width: 76px;
    text-align: center;
    padding: 7px;
    font-weight: 500;
    cursor: pointer;
}

.thresholdSave.disabled {
    cursor: not-allowed;
}

.feedback {
    background-color: var(--white);
    border: none;
    color: var(--blue-royal);
    margin-right: 40px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.cancelText {
    cursor: pointer;
    margin-left: 10px;
}

.taskComplete.disabled {
    background-color: var(--disabled-blue-grey);
    border-color: var(--white);
}

.taskComplete.disabled:hover {
    cursor: not-allowed;
    color: var(--white);
}

.cancelButton {
    border-color: var(--md-blue-grey);
    color: var(--md-blue-grey);
}

.cancelButton:hover {
    background-color: var(--md-blue-grey);
    border-color: var(--md-blue-grey);
    color: var(--white);
}

.taskSave {
    border-color: var(--md-blue);
    color: var(--md-blue);
    margin-right: 24px;
}

.taskSave:hover {
    background-color: var(--md-blue);
    border-color: var(--md-blue);
    color: var(--white);
}

.commentSave.disabled {
    cursor: not-allowed;
}

.clear {
    color: var(--blue-royal);
    cursor: pointer;
    font-size: 16px;
    text-align: right;
}

.controlPrimary,
.controlSecondary {
    cursor: pointer;
    max-width: 154px;
    width: auto;
    height: auto;
    padding: 8px 18px;
}

.controlSecondary {
    color: #0066b2;
}

.controlPrimary.disabled {
    background-color: #dbdcdd;
    color: white;
    cursor: not-allowed;
}

.controlSecondary.disabled {
    color: #6b6d6f;
    cursor: not-allowed;
}
