.sidebarContent {
    height: 100vh;
    position: relative;
    width: 100%;
}

.actions {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.actions>span {
    color: var(--blue-sky);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.38;
    text-align: center;
}

.actions>span:hover {
    cursor: pointer;
}

.actionDivider {
    color: var(--white);
    display: inline-block;
}

.logout {
    margin-right: 1rem;
}

.feedback {
    margin-left: 1rem;
    text-decoration: none;
    color: var(--blue-sky);
    font-family: 'Gotham', sans-serif;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.38;
    text-align: center;
}

.logout:hover,
.feedback:hover {
    text-decoration: underline;
}

.hasAdmin:last-child {
    border-top: solid 1px var(--blue-royal);
    bottom: 0;
    position: absolute;
    width: 100%;
}

.closeButton {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

@media (max-width:1320px) {
    .closeButton {
        top: 10px;
    }

    .actions {
        margin-bottom: 20px;
    }
}