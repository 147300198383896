.status {
    align-items: center;
    display: flex;
    font-size: 24px;
    justify-content: center;
}

.retryContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.retryButton {
    padding-top: 10px;
    color: var(--cerulean);
    cursor: pointer;
    display: flex;
    font-weight: 500;
    font-size: 16px;
}

.spinner {
    height: 32px;
    margin-right: 10px;
    width: 32px;
}
