.modalLabel {
    display: inline-block;
    color: var(--grey-charcoal);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    padding-right: 10px;
}

.flexContainer{
    width: 100%;
    text-align: left;
    color: var(--grey-charcoal);
    font-size: 16px;
}
.close_btn{
    color: #0066b2;
    margin: auto;
    text-align: center;
    background: transparent ;
    border: 0 none;
    width: 100%;
    cursor: pointer;
}
.close_btn:hover{
    text-decoration: underline;
}
.close_btn:focus,.close_btn:active{
    outline: none;
}